.dropdown1 {
  position: relative;
  display: inline-block;
  text-align: left;
}

.dropdown1-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown1-content .dropdown-item {
  color: black;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  padding: 15px;
}

.dropdown1-content .dropdown-item:hover {
  background-color: #f1f3f887;
}
.dropdown1:hover .dropdown1-content {
  display: block;
}

.dropdown1:hover .dropbtn {
  background-color: #3e8e41;
}
