/*=========================================================================================
    File Name: main-menu.scss
    Description: Styles for menu components, main navigation and menu itself.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy React Admin Template
    Version: 1.0
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

.nowrap-text {
  white-space: nowrap;
}
.flex-center-spacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pointer-cursor {
  cursor: pointer;
}
.w-100p {
  width: 88%;
}
.flex-center-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center-spaceeven {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.flex-end-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
}
.flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.flex-center-left {
  display: flex;
  align-items: center;
  justify-content: first baseline;
  height: 100%;
  text-align: left;
}
.backgroung-Img-CompleteUrapplication {
  background: url($complete-ur-app-bg);
  background-color: #f6f7f8;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.addnewcard-emp-btn {
  min-width: 100%;
  font-weight: 500;
  font-size: 16px;
  min-height: 55px;
}
