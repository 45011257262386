// ===============================================================================================
//  File Name: error.scss
//  Description: Page content different user error page layouts SCSS.
//  ----------------------------------------------------------------------------------------------
//  Item Name: Vuexy React Admin Template
//  Version: 1.0
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

.error-code {
  font-size: 12px;
}

.error-center {
  font-size: 12px;
  font-family: Raleway , sans-serif;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ff3333;
  opacity: 1;
  text-align: center;
}
