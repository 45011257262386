.collapse-icon {
  .card-header {
    position: relative;
  }

  .card-header {
    &:after {
      position: absolute;
      top: 48%;
      right: 20px;
      margin-top: -8px;
      font-family: "feather" , sans-serif;
      content: "\e845";
      transition: all 300ms linear 0s;
      font-size: 0.9rem;
    }

    &:before {
      position: absolute;
      top: 48%;
      right: 20px;
      margin-top: -8px;
      font-family: "feather" , sans-serif;
      content: "\e842";
      transition: all 300ms linear 0s;
      font-size: 0.9rem;
    }
  }

  &.left {
    a {
      padding-left: 28px;

      &:before,
      &:after {
        left: 20px;
        right: auto;
      }
    }
  }

  &.accordion-icon-rotate {
    .collapse.show {
      &:before {
        transform: rotate(180deg);
      }
    }

    .collapse:not(.show) {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }
}
