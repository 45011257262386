/* ===============================================================================================
    File Name: navbar.scss
    Description: Contain header navigation bar, vertical main navigation bar and
    horiznotal main navigation bar related SCSS.
    ----------------------------------------------------------------------------------------------
    Item Name: Vuexy React Admin Template
    Version: 1.0
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
================================================================================================*/

%extend_hamburger_color {
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: $white;
  }
}

@function set-navbar-text-color($color) {
  @if (lightness($color) > 50) {
    @return $white; // Lighter backgorund, return dark color
  } @else {
    @return $black; // Darker background, return light color
  }
}

@mixin fullNav {
  .navbar-header {
    width: 100% !important;
    padding: 0.5rem 1rem;
    position: relative;

    .menu-toggle {
      top: 2px;
      position: relative;
    }

    .open-navbar-container i {
      font-size: 1.8rem;
    }

    .navbar-brand {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
    }
  }

  &.navbar-with-menu {
    .navbar-container {
      width: 100%;
      display: table;
      margin: 0;
      height: auto;
    }
  }
}

.header-navbar-shadow {
  display: block;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 97.3%, 0.95) 44%,
    hsla(0, 0%, 97.3%, 0.46) 73%,
    hsla(0, 0%, 100%, 0)
  );
  padding-top: 2.2rem;
  background-repeat: repeat;
  width: 100%;
  height: 102px;
  position: fixed;
  top: 0;
  z-index: 11;
}

.header-navbar {
  padding: 0px;
  min-height: $navbar-height - 0.5;
  font-family: $font-family-monospace;
  transition: 300ms ease all;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 97.3%, 0.95) 44%,
    hsla(0, 0%, 97.3%, 0.46) 73%,
    hsla(0, 0%, 100%, 0)
  );
  z-index: 1000;
  &.fixed-top {
    left: $menu-expanded-width;
  }

  &[class*="bg-"] {
    .navbar-nav {
      .nav-item {
        a {
          color: set-navbar-text-color($theme-dark-text-color) !important;
          &.dropdown-item {
            span,
            svg {
              color: $body-color !important;
            }
            &:hover {
              span,
              svg {
                color: $white !important;
              }
            }
          }
        }
        svg,
        span {
          color: set-navbar-text-color($theme-dark-text-color) !important;
        }
      }
    }
  }

  &.floating-nav {
    margin: 1.3rem #{$content-padding} 0;
    border-radius: 0.5rem;
    position: fixed;
    width: calc(100% - calc(#{$content-padding} * 2) - #{$menu-expanded-width});
    z-index: 11;
    right: 0;
  }

  &.navbar-static-top {
    background: transparent;
    box-shadow: none !important; // remove this if removing "menu-shadow" class
  }

  .navbar-wrapper {
    width: 100%;
  }

  // add border to navbar
  &.navbar-border {
    border-bottom: 1px solid $custom-border-color;
  }

  &.navbar-dark.navbar-border {
    border-bottom: 1px solid #8596b5;
  }

  // add shadow to navbar
  &.navbar-shadow {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  }

  // hide top horizontal menu drop-down arrow
  .nav-link {
    &.dropdown-toggle::after {
      display: none;
    }
  }

  .navbar-container ul.nav li.dropdown-language {
    padding-right: 0;
    .country-flag {
      width: 1.5rem !important;
    }
    .selected-language {
      font-size: 0.97rem;
      letter-spacing: -0.25px;
      font-weight: 500;
    }
  }

  .navbar-container {
    padding-left: $spacer;
    // height: $navbar-height;
    transition: 300ms ease all;
    background: inherit;
    margin-left: 0;
    ul.navbar-nav {
      li {
        line-height: 0.5;
        > .nav-link {
          padding: 1.4rem 0.5rem 1.35rem;
          svg {
            &:hover {
              color: $primary;
            }
          }
        }
        &.dropdown-notification {
          .dropdown-menu:focus {
            outline: none;
          }
        }
      }
      &.navbar-nav-user {
        li:not(:last-child) > .nav-link {
          margin-right: 0.5rem;
        }
      }
    }

    // for bookmark z-index
    .bookmark-input {
      z-index: 1;
    }

    ul.nav {
      li {
        &.dropdown .dropdown-menu {
          top: 48px;
          // top: 90%;
          margin-top: -4px;
        }

        .badge {
          padding: 0.42em 0.6em 0.25rem;

          &.badge-up {
            position: absolute;
            top: 12px;
            right: -7px;
          }
        }

        &.dropdown-language {
          > a.nav-link {
            padding: 1.75rem 0.5rem;
          }
          .dropdown-menu {
            min-width: 12rem;
          }
        }

        > .nav-link {
          color: $body-color;
          padding: 1.4rem 0.5rem 1.35rem;

          i.flag-icon {
            margin-right: 0.5rem;
          }
        }

        &.dropdown-user {
          .dropdown-menu-right {
            right: 26px;
            left: -12px !important;
            padding: 0.5rem;

            .dropdown-item {
              padding: 10px;

              &:hover,
              &:active {
                background: $primary;
                span,
                svg {
                  color: $white;
                }
              }
            }
          }
        }

        a.dropdown-user-link {
          padding: 0.7rem 1rem;
          display: flex;
          align-items: center;

          .user-name {
            display: inline-block;
            margin-bottom: 0.435rem;
            margin-left: 0.2rem;
            line-height: 1;
          }

          .user-status {
            font-size: smaller;
          }
          img {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12),
              0 2px 4px 0 rgba(0, 0, 0, 0.08);
          }
        }

        a.menu-toggle {
          // padding: 1.1rem 0.8rem 1rem 1rem;
          padding: 1.34rem 0.22rem 1.35rem 0;

          i {
            font-size: 1.75rem;
          }
        }

        a.nav-link-label {
          padding: 1.6rem 0.2rem 1.35rem 0.8rem;
        }

        a.dropdown-user-link {
          .user-nav {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            float: left;
            margin-right: $spacer - 0.2;
          }
        }

        a.nav-link-search,
        a.nav-link-expand {
          // padding: 1.4rem 1rem 1.3rem 1rem;
        }

        div.input-group {
          padding: 0.7rem 1rem;
        }

        i.ficon {
          font-size: 1.5rem;
          color: $body-color;
          &:hover {
            color: $primary;
          }
        }

        .media-list {
          max-height: 24rem;
          line-height: 1;
        }

        .scrollable-container {
          position: relative;
        }
      }
    }

    //Navbar notification css
    .dropdown-menu-media {
      width: 26rem;
      left: 10px !important;
      // overflow: hidden;
      .media-list {
        cursor: pointer;
        .media {
          padding: 1rem;
          border: none;
          border-bottom: 1px solid $gray-300;

          &:hover {
            background: $body-bg;
          }

          .media-meta {
            color: $body-color;
          }
        }
        a:last-of-type {
          .media {
            border-bottom: none;
          }
        }
      }

      .dropdown-menu-header {
        border-bottom: 1px solid $gray-300;
        padding: 1.25rem;
        .dropdown-header {
          padding: 0;
        }
      }

      .dropdown-menu-footer {
        a {
          padding: 0.3rem;
          border-top: 1px solid $gray-300;
        }
      }
      &.dropdown-cart {
        left: 5px !important;
        &.empty-cart {
          &:before {
            background: $white;
            border-color: rgba($color: $pure-black, $alpha: 0.1);
          }
        }
        .media-left {
          width: 100px;
        }
      }
    }
  }

  &.navbar-without-menu {
    .navbar-container {
      margin-left: 0;
    }
  }

  &.navbar-with-menu {
    .navbar-container {
    }
  }

  .nav-item + .nav-item {
    margin-left: 0rem;
  }

  /* For sm screen*/
  @include media-breakpoint-down(sm) {
    @include fullNav;
  }
}

/* Modern menu For md screen*/
@include media-breakpoint-down(md) {
  [data-menu="vertical-menu-modern"] {
    .header-navbar {
      @include fullNav;
    }

    .navbar-dark,
    .navbar-semi-dark {
      .navbar-header .navbar-nav .nav-link {
        color: $white;
      }

      .navbar-container .navbar-nav .nav-link {
        color: $gray-600;
      }
    }

    // .navbar-light {
    //   .navbar-header .navbar-nav .nav-link {
    //     color: $gray-600;
    //   }
    // }
  }
}

// Navbar colors

.navbar-light {
  background: $white;

  &.navbar-horizontal {
    background: $white;
  }

  .navbar-nav {
    li {
      line-height: 1;
    }

    .active {
      &.nav-link {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }

    .disabled {
      &.nav-link {
        color: $nav-link-disabled-color;
      }
    }
  }
}

.navbar-dark {
  background: $gray-600;

  .navbar-brand {
    color: $white !important;
  }

  &.navbar-horizontal {
    background: $gray-600;
  }

  @extend %extend_hamburger_color;

  .nav-search {
    .form-control,
    .btn-secondary {
      color: $white;
      background: $gray-600;
    }
  }

  .navbar-nav {
    li {
      line-height: 1;
    }

    .active {
      &.nav-link {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }

    .disabled {
      &.nav-link {
        color: $gray-300;
      }
    }
  }
}

.navbar-semi-dark {
  .navbar-header {
    background: $gray-600;

    .brand-text {
      color: $white;
    }
  }

  background: $white;

  .navbar-nav {
    li {
      line-height: 1;
    }

    .nav-link {
      color: $gray-600;
    }

    .active {
      &.nav-link {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }

    .disabled {
      &.nav-link {
        color: $nav-link-disabled-color;
      }
    }
  }
}

.navbar-semi-light {
  .navbar-header {
    background: $white;

    .brand-text {
      color: $gray-600;
    }
  }

  background: $gray-600;

  .navbar-nav {
    li {
      line-height: 1;
    }

    .nav-link {
      color: $white;
    }

    .active {
      &.nav-link {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }

    .disabled {
      &.nav-link {
        color: $nav-link-disabled-color;
      }
    }
  }
}

@-moz-document url-prefix() {
  ul {
    li {
      a {
        .children-in {
          position: relative;
          right: 3px;
          top: -14px;
        }
      }
    }
  }
}

.navbar-menu-icon {
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $gray-600;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
}

.navbar-menu-icon span:nth-child(1) {
  top: 0px;
}

.navbar-menu-icon span:nth-child(2) {
  top: 10px;
}

.navbar-menu-icon span:nth-child(3) {
  top: 20px;
}

.navbar-menu-icon.show span:nth-child(1) {
  top: 10px;
  transform: rotate(135deg);
}

.navbar-menu-icon.show span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.navbar-menu-icon.show span:nth-child(3) {
  top: 10px;
  transform: rotate(-135deg);
}

// Media queries for device support
// =========================================
@include media-breakpoint-down(sm) {
  .header-navbar {
    // generic navbar dropdown specific
    .navbar-nav {
      .show {
        position: static;
      }

      .open-navbar-container {
        padding-top: 0.625rem;
      }
    }

    .navbar-container {
      .show {
        .dropdown-menu {
          right: 0;
          left: 0 !important;
          float: none;
          width: auto;
          margin-top: 0;
          overflow: hidden;
        }
      }

      ul.nav {
        li {
          &.nav-item i {
            margin-right: 0.2rem;
          }

          a.dropdown-user-link {
            padding: 0.8rem 0.6rem;
          }

          .dropdown-toggle::after {
            margin-right: 0;
            margin-left: -2px;
          }
        }
      }
    }
  }

  // dark navbar
  .navbar-dark,
  .navbar-semi-dark {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $white;
    }

    .navbar-header {
      .navbar-nav {
        .nav-link {
          color: $white;
        }
      }
    }

    .navbar-container {
      .navbar-nav {
        .nav-link {
          color: $gray-600;
        }
      }
    }
  }

  // light navbar
  .navbar-light,
  .navbar-semi-light {
    .navbar-header {
      .navbar-nav {
        .nav-link {
          // color: $gray-600;
        }
      }
    }

    .navbar-container {
      .navbar-nav {
        .nav-link {
          // color: $gray-600;
        }
      }
    }
  }

  .header-navbar {
    .navbar-container {
      .dropdown-notification,
      .dropdown-language {
        .dropdown-menu {
          right: 0 !important;
          left: 31px !important;
        }
      }
    }
  }
}

.headroom {
  will-change: transform;
  transition: transform 200ms linear;
}

.headroom--pinned-top {
  transform: translateY(0%);
}

.headroom--unpinned-top {
  transform: translateY(-100%);
}

.headroom--pinned-bottom {
  transform: translateY(0%);
}

.headroom--unpinned-bottom {
  transform: translateY(100%);
}

@include media-breakpoint-down(xs) {
  .header-navbar {
    .navbar-container ul.nav li .selected-language {
      display: none;
    }
  }
}

// header navbar when floating nav
body {
  &[data-col="1-column"] {
    .header-navbar {
      &.floating-nav {
        width: calc(100% - calc(#{$content-padding} * 2));
      }
    }
  }
}

// header navbar when floating nav
@media (min-width: 1200px) and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
  body {
    &:not([data-col="1-columns"]) {
      .header-navbar {
        &.floating-nav {
          width: calc(100% - 60px - #{$menu-expanded-width});
        }
      }
    }
  }
}

// For Medium and down: iPad support for navbr

@include media-breakpoint-down(lg) {
  .header-navbar {
    .navbar-container {
      ul.nav {
        li {
          &.dropdown-language {
            > a.nav-link {
              padding: 1.75rem 0.5rem;
            }
          }

          a.dropdown-user-link {
            padding: 0.7rem 1.23rem;
          }

          .dropdown-toggle::after {
            margin-right: 0;
            margin-left: -2px;
          }
        }
      }
    }
  }
}

@mixin extend_mobile_specific($menu_height) {
  .header-navbar {
    .navbar-container {
      .show {
        .dropdown-menu {
          max-height: $menu_height;
        }
      }
    }
  }
}

@mixin responsive_sub_nav {
  //For responsive sub nav
  #navbar-mobile.navbar-collapse {
    .navbar-nav {
      margin: 0;
      flex-flow: row wrap;

      .dropdown-menu {
        position: absolute;
      }

      .nav-item {
        float: left;
      }
    }
  }
}

@media (max-width: 767px) {
  @include responsive_sub_nav;
}

@include media-breakpoint-down(md) {
  @include responsive_sub_nav;
}

/* ----------- iPhone 5, 5S  iPhone 6----------- */

/* Landscape */

@media only screen and (min-device-width: 26.78em) and (max-device-width: 47.64em) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  @include extend_mobile_specific(180px);
}

/* ----------- iPhone 6+ ----------- */

/* Landscape */

@media only screen and (min-device-width: 29.57em) and (max-device-width: 52.57em) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  @include extend_mobile_specific(280px);
}

/*----------------------------- For Navbar Component -------------------------*/

#navbar-component {
  .navbar {
    position: relative;
    width: auto;
    &.floating-nav {
      border-radius: 0.5rem;
    }
    z-index: auto;
    // navbar dark color

    &.header-navbar.navbar-dark {
      .navbar-nav {
        .nav-link {
          color: $white;
        }
      }
    }

    .navbar-nav {
      .dropdown-language,
      .dropdown-notification,
      .dropdown-user {
        &.show {
          position: relative;
        }
        .dropdown-menu {
          &.show {
            position: absolute;
            right: 0;
            // left: auto !important;
          }
        }
      }
    }

    // for font size of navbar toggler

    &.navbar-dark,
    &.navbar-light {
      .navbar-toggler {
        font-size: 1.2rem;
        &:focus {
          outline: 0;
        }
      }
    }
  }

  // to remove border radius for collapsed navbar

  #navbar-collapsed {
    .navbar {
      border-radius: 0;
    }
  }
}

// for alignment of language dropdown and name
@media (min-width: 992px) {
  #navbar-component {
    .navbar {
      .navbar-container {
        background: inherit;
      }
    }
    .nav {
      .dropdown-language {
        float: right;
      }
    }
  }
}

// background color

@media (max-width: 992px) {
  #navbar-component {
    .navbar {
      .navbar-container {
        background: inherit;
        .navbar-nav {
          flex-direction: row;
        }
      }
    }
  }
}
// for width of navbar in fixed-top
@media (max-width: 1200px) {
  .header-navbar {
    &.fixed-top {
      left: 0;
    }
  }
}

// for notification dropdown of navbar component page
@media (max-width: 768px) {
  #navbar-component {
    .navbar {
      .navbar-nav {
        .dropdown-notification {
          &.show {
            position: static;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .header-navbar.floating-nav {
    width: calc(100% - 2.4rem) !important;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }
  .content.app-content .content-area-wrapper {
    margin-left: 1.2rem !important;
    margin-right: 1.2rem !important;
  }
}

// 1-column Layout - Back Link in Center
@media (max-width: 992px) {
  .navbar-container {
    #navbar-mobile {
      .nav-back {
        margin-top: 5px;
      }
    }
  }
}
.vault-user-name {
  font-size: 13px;
  font-family: Montserrat , sans-serif;
  font-weight: 600;
  color: #2e2e2e;
  opacity: 1;
}
.vault-user-email {
  font-size: 12px;
  font-family: Montserrat , sans-serif;
  font-weight: 500;
  color: #2e2e2e;
  opacity: 0.57;
}
