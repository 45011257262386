// ================================================================================================
//     File Name: Footer.scss
//     Description: *  Main footer styles.
//     ----------------------------------------------------------------------------------------------
//     Item Name: Vuexy React Admin Template
//     Version: 1.0
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Stick footer to the bottom of the page
footer {
  &.footer {
    padding: $spacer ($spacer * 2.2);

    // a tag margin - PIXINVENT text
    a {
      margin: 0 0.3rem;
    }
    // heart icon scss
    span {
      svg {
        margin-left: 0.5rem;
      }
    }
    .scroll-top {
      padding: 0.81rem 0.83rem;
    }
  }

  //shadow & border options
  &.navbar-shadow {
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.15);
  }
  &.navbar-border {
    border-top: 1px solid $custom-border-color;
  }
}

// Fixed Footer
.fixed-footer {
  // Footer Light
  &.footer-light {
    background: $white;
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.15);
  }
  // Footer Dark
  &.footer-dark {
    background: $gray-600;
    color: $white;
  }
}

// scroll to Top Footer Button
.scroll-top {
  position: fixed;
  bottom: 5%;
  display: none;
  right: 30px;
  z-index: 99;
}

@include media-breakpoint-up(lg) {
  .footer {
    margin-left: 260px;
  }
}
.footerFontColor {
  font-family: Raleway , sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #525c66;
}
