.group-area {
  margin-bottom: 3rem;
}

// Used many times
.block {
  display: block;
  width: 100%;
}

// select[multiple="multiple"] {
// overflow: hidden;
// }

/* Input with icon */

.form-control-position {
  position: absolute;
  top: 1px;
  right: 0;
  z-index: 2;
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
}

/*---------------------------------
Input Icon
---------------------------------*/

.position-relative {
  .form-control {
    padding-right: $input-height;
    &.form-control-lg {
      padding-left: 2.5rem;
      & ~ .form-control-position {
        top: 9px;
      }
    }
    &.form-control-sm {
      & ~ .form-control-position {
        top: -3px;
      }
    }
  }
}

/* Input Icon left */

.has-icon-left {
  // form-control - padding
  .form-control {
    padding-right: $input-btn-padding-x;
    padding-left: 3rem;
  }
  .form-control-position {
    right: auto;
    left: inherit;
    // icon - padding
    svg {
      position: relative;
      left: 5px;
      color: rgba($black, 0.4);
    }
  }
}

input[type="color"] {
  height: $input-height;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  line-height: $line-height-base;
}

.font-size-large {
  font-size: $font-size-lg;
}

.font-size-base {
  font-size: $font-size-base;
}

.font-size-small {
  font-size: $font-size-sm;
}

.font-size-xsmall {
  font-size: $font-size-xs;
}

.input-group-xs {
  .form-control,
  .input-group-addon,
  .input-group-btn > button {
    padding: 0.175rem 0.5rem;
    font-size: 0.725rem;
    line-height: 1.677777;
    border-bottom-left-radius: 0.175rem;
    border-top-left-radius: 0.175rem;
  }
  .input-group-btn > button {
    border-radius: 0.175rem;
  }
}

.input-group-addon {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.floating-label-form-group-with-focus label,
.floating-label-form-group-with-value label {
  color: $primary !important;
  text-transform: uppercase;
  font-weight: 400;
}
